'use client'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import {XMarkIcon} from "@heroicons/react/16/solid";

export default function CustomModal({ children, title, isOpenMobileNav, setIsOpenMobileNav }) {
  return (
    <Dialog open={isOpenMobileNav} onClose={setIsOpenMobileNav} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex max-h-full h-full items-start justify-center text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="modal-container h-full md:h-[70%] overflow-y-auto relative transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-[620px] py-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="flex justify-end mr-6">
             <button className="text-yellow-600 font-bold text-xl px-2 bg-orange-300 rounded-lg" onClick={() => setIsOpenMobileNav(false)}>X</button>
            </div>
            <div className="text-center">
              <DialogTitle as="h3" className="text-[18px] md:text-[22px] font-bold leading-6 text-[#333F51] mt-4">
                {title ? title : 'Форма регистрации курьера'}
              </DialogTitle>
              <div className="mt-2">
                {children}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
