import React, {useEffect, useRef, useState} from 'react';
import Arrow from "../images/arrowSelect.png";
import ArrowTop from "../images/arrowSelectTop.png";

const typeCar = ["Велосипед", "Мотоцикл (мопед)", "Автомобиль", "Пеший курьер"];

const SelectTypeCar = ({ formik }) => {
  const [selectedType, setSelectedType] = useState('Выберите здесь');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectType = (type) => {
    setSelectedType(type);
    formik.setFieldValue('car_type', type)

    if (type !== "Автомобиль") {
      setIsOpen(false);
    }
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formik.values.car_name !== '' && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div
        onClick={toggleDropdown}
        className="max-h-[50px] flex justify-between items-center p-4 border border-1 border-gray-400 rounded-full cursor-pointer hover:border-orange-500"
      >
        <span className={`text-base ${selectedType === 'Выберите здесь' ? 'text-gray-400' : 'text-[#333F51]'}`}>
          {selectedType}
        </span>
        <img src={isOpen ? ArrowTop : Arrow} alt="arrow icon" />
      </div>

      {isOpen && (
        <div className="p-3 overflow-y-scroll max-h-[200px] absolute mt-2 w-full border border-1 border-gray-400 rounded-xl bg-white z-10">
          {typeCar.map((type, index) => (
            <div
              key={index}
              className="p-3 hover:bg-gray-100 border-b rounded-xl cursor-pointer text-start text-[#333F51]"
              onClick={() => selectType(type)}
            >
              <span className="text-start text-[#333F51]">{type}</span>
            </div>
          ))}
          {selectedType === "Автомобиль" && (
            <input
              name="car_name"
              value={formik.values.car_name}
              onChange={formik.handleChange}
              className="mt-6 border border-1 border-gray-400 rounded-full h-[45px] max-w-[650px] px-[20px] w-full"
              placeholder="Название автомобиля"
              autoFocus
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SelectTypeCar;
